import { createStore } from 'vuex'

export default createStore({
  state: {
    api_url : 'https://precise.sizemorechauffers.com/',
    logged_in : localStorage.getItem('logged_in')
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
