<template>

<section>
     <!-- Begin page -->
        <div id="wrapper">
            <Header />
            <SideBar />


            <!-- ============================================================== -->
            <!-- Start Page Content here -->
            <!-- ============================================================== -->

            <div class="content-page">
                <div class="content">
                    
                    <!-- Start Content-->
                    <div class="container-fluid">
                        <div class="row page-title">
                            <div class="col-md-12">
                               
                                <h4 class="mb-1 mt-0">New battery</h4>
                            </div>
                        </div>


                        <div class="card">
                            <div class="card-body">
                                <h6 class="mb-1 mt-0">This information will be used on the search page</h6>
                                <form @submit.prevent="new_battery()">
                                    <div class="row">
                                        <div class="col-md-6 mt-3">
                                            <input type="text" placeholder="Vehicle type" v-model="vehicle_type" class="form-control">
                                        </div>

                                         <div class="col-md-6 mt-3">
                                            <input type="text" placeholder="Size(Part no.)" v-model="size" class="form-control">
                                        </div>

                                          <div class="col-md-6 mt-3">
                                            <input type="text" placeholder="Brand" v-model="brand" class="form-control">
                                        </div>

                                          <div class="col-md-6 mt-3">
                                            <input type="text" placeholder="Retail price" v-model="price" class="form-control">
                                        </div>

                                          <div class="col-md-6 mt-3">
                                            <button class="btn btn-primary text-warning" :disabled="disabled">{{text}}</button>
                                        </div>
                                        
                                    </div>
                                </form>
                            </div>
                        </div>



                    </div> <!-- container-fluid -->

                </div> <!-- content -->

                
                <Footer />
               

            </div>

            <!-- ============================================================== -->
            <!-- End Page content -->
            <!-- ============================================================== -->


        </div>
        <!-- END wrapper -->
</section>

</template>



<script>
	import axios from 'axios'
    import Header from './layouts/Header'
    import SideBar from './layouts/SideBar'
    import Footer from './layouts/Footer'

	export default {
		name : 'new-battery',
        components : {Header,SideBar,Footer},

		data(){
			return {
			vehicle_type : '',
            size : '',
            brand : '',
            price : '',
            text : 'Submit',
            disabled : false	
			}
		},

		methods : {
			async new_battery(){
                if(!this.vehicle_type){
                    alert('Vehicle type cannot be blank!')
                    return 
                }

                 if(!this.size){
                    alert('Size or part no. cannot be blank!')
                    return 
                }

                 if(!this.brand){
                    alert('Brand cannot be blank!')
                    return 
                }

                 if(!this.price){
                    alert('Price cannot be blank!')
                    return 
                }

                this.text = 'Please wait...'
                this.disabled = true

                const res = await axios.post(this.$store.state.api_url+'api/new-battery',{
                    vehicle_type : this.vehicle_type,
                    size : this.size,
                    brand : this.brand,
                    price : this.price
                }).then(function(response){
                    return response.data
                }).catch(function(error){
                    console.log(error)
                })
                alert('Battery added to list')
                this.vehicle_type = ''
                this.size = ''
                this.price = ''
                this.brand = ''

                this.text = 'Submit'
                this.disabled = false
            }
		}
	}

</script>