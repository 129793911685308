import { createRouter, createWebHistory } from 'vue-router'
import Login from '../components/Login'
import Dashboard from '../components/Dashboard'
import NewBattery from '../components/NewBattery'
import ListBatteries from '../components/ListBatteries'
import NewTyre from '../components/NewTyre'
import Tyres from '../components/Tyres'

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },

   {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },

   {
    path: '/new-battery',
    name: 'new-battery',
    component: NewBattery
  },

    {
    path: '/batteries',
    name: 'batteries',
    component: ListBatteries
  },

    {
    path: '/new-tyre',
    name: 'new-tyre',
    component: NewTyre
  },

   {
    path: '/tyres',
    name: 'tyres',
    component: Tyres
  },


  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
