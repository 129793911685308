<template>

<section>
     <!-- Begin page -->
        <div id="wrapper">
            <Header />
            <SideBar />


            <!-- ============================================================== -->
            <!-- Start Page Content here -->
            <!-- ============================================================== -->

            <div class="content-page">
                <div class="content">
                    
                    <!-- Start Content-->
                    <div class="container-fluid">
                        <div class="row page-title">
                            <div class="col-md-12">
                                
                                <h4 class="mb-1 mt-0">Dashboard</h4>
                            </div>
                        </div>


                        <div class="row">

                            <div class="col-md-4">
                                <div class="card">

                                    <div class="card-body">
                                        <h6> <i class="bi bi-battery text-warning"></i> Batteries</h6>
                                        <router-link to="/new-battery" class="btn btn-primary text-warning mr-1">Add new</router-link>
                                         <router-link to="/batteries" class="btn btn-primary text-warning">List</router-link>
                                    </div>
                                    
                                </div>
                            </div>


                             <div class="col-md-4">
                                <div class="card">

                                    <div class="card-body">
                                        <h6> <i class="bi bi-0-circle text-warning"></i> Tyres</h6>
                                        <router-link to="/new-tyre" class="btn btn-primary text-warning mr-1">Add new</router-link>
                                         <router-link to="/tyres" class="btn btn-primary text-warning">List</router-link>
                                    </div>
                                    
                                </div>
                            </div>
                            
                        </div>


                    </div> <!-- container-fluid -->

                </div> <!-- content -->

                
                <Footer />
               

            </div>

            <!-- ============================================================== -->
            <!-- End Page content -->
            <!-- ============================================================== -->


        </div>
        <!-- END wrapper -->
</section>

</template>



<script>
    import axios from 'axios'
    import Header from './layouts/Header'
    import SideBar from './layouts/SideBar'
    import Footer from './layouts/Footer'

    export default {
        name : 'dashboard',
        components : {Header,SideBar,Footer},

        data(){
            return {
                
            }
        },

        methods : {
            
        }
    }

</script>