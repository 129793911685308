<template>

<section>
     <!-- Begin page -->
        <div id="wrapper">
            <Header />
            <SideBar />


            <!-- ============================================================== -->
            <!-- Start Page Content here -->
            <!-- ============================================================== -->

            <div class="content-page">
                <div class="content">
                    
                    <!-- Start Content-->
                    <div class="container-fluid">
                        <div class="row page-title">
                            <div class="col-md-12">
                               
                                <h4 class="mb-1 mt-0">List tyres</h4>
                            </div>
                        </div>


                        <div class="card">
                            <div class="card-body">
                                <h6 class="mb-1 mt-0">List of batteries</h6>
                                <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr> <th>Pattern</th> <th> Size </th> <th> Brand </th> <th>Retail price</th> <th>Action...</th> </tr>
                                    </thead>

                                    <tbody v-for="tyre in tyres">
                                        <tr> <td> {{tyre.description}} </td> <td> {{tyre.size}} </td> <td> {{tyre.brand}} </td> <td> Ksh.{{tyre.cost}} </td><td> <button class="btn text-dark" @click="tyre_details(tyre.id)"> <i class="bi bi-pencil-square"></i> </button> <button class="btn" @click="delete_tyre(tyre.id)"> <i class="bi bi-x-lg text-danger"></i> </button> </td> </tr>
                                    </tbody>
                                </table>
                            </div>
                                
                            </div>
                        </div>



                    </div> <!-- container-fluid -->

                </div> <!-- content -->


                <div style="position: fixed; background-color:#fff; top: 0; right: 0; z-index: 900;" class="shadow-lg p-3 m-3 rounded" v-if="show_modal">
                    <h6>Edit battery</h6>
                    <form @submit.prevent="edit_tyre()" enctype="multipart/form-data">
                                    <div class="row">
                                        <div class="col-md-12 mt-3">
                                            <label>Photo</label>
                                            <input type="file" placeholder="Photo" class="form-control" @change="get_image">
                                        </div>
                                        <div class="col-md-6 mt-3">
                                            <label>Vehicle type</label>
                                            <input type="text" placeholder="Vehicle type" v-model="description" class="form-control">
                                        </div>

                                         <div class="col-md-6 mt-3">
                                            <label>Size</label>
                                            <input type="text" placeholder="Size(Part no.)" v-model="size" class="form-control">
                                        </div>

                                          <div class="col-md-6 mt-3">
                                            <label>Brand</label>
                                            <input type="text" placeholder="Brand" v-model="brand" class="form-control">
                                        </div>

                                          <div class="col-md-6 mt-3">
                                            <label>Cost</label>
                                            <input type="text" placeholder="Retail price" v-model="cost" class="form-control">
                                        </div>

                                          <div class="col-md-6 mt-3">
                                            <button type="button" class="btn btn-primary text-warning" @click="show_modal=false" style="margin-right: 10px;">Close</button>
                                            <button class="btn btn-primary text-warning">Save changes</button>
                                        </div>
                                        
                                    </div>
                                </form>
                </div>

                
                <Footer />
               

            </div>

            <!-- ============================================================== -->
            <!-- End Page content -->
            <!-- ============================================================== -->


        </div>
        <!-- END wrapper -->
</section>

</template>



<script>
	import axios from 'axios'
    import Header from './layouts/Header'
    import SideBar from './layouts/SideBar'
    import Footer from './layouts/Footer'

	export default {
		name : 'tyres',
        components : {Header,SideBar,Footer},

		data(){
			return {
			tyres : [],
             description : '',
            brand : '',
            cost : '',
            size : '',
            show_modal : false,
            id : '',
            details : [],
            image : ''	
			}
		},

		methods : {

              get_image(e){
            this.image = e.target.files[0] 
        },

			async list_tyres(){
                const res = await axios.get(this.$store.state.api_url+'api/tyres').then(function(response){
                    return response.data
                }).catch(function(error){
                    console.log(error)
                })
                this.tyres = res
            },

                async tyre_details(id){
                this.id = id 
                const res = await axios.get(this.$store.state.api_url+'api/tyre-details/'+id).then(function(response){
                    return response.data
                }).catch(function(error){
                    console.log(error)
                })
                this.details = res
                res.forEach((data)=>{
                    this.cost  = data.cost 
                    this.description = data.description
                    this.size = data.size
                    this.brand = data.brand

                })
                this.show_modal = true 

            },

            async delete_tyre(id){

                if(confirm('Are you sure you want to delete this item?')){
                       const res = await axios.post(this.$store.state.api_url+'api/delete-tyre',{
                    id : id
                }).then(function(response){
                    return response.data
                }).catch(function(error){
                    console.log(error)
                })
                this.list_tyres()
                }else{
                    //pass  
                }

             
            },

               async edit_tyre(){

                  //upload a file
const config = {
       headers: { 'content-type': 'multipart/form-data' }
   }
   //upload a file

                const res = await axios.post(this.$store.state.api_url+'api/edit-tyre',{
                    id : this.id,
                    description : this.description,
                    size : this.size,
                    cost : this.cost,
                    brand : this.brand,
                    image : this.image
                },config).then(function(response){
                    return response.data
                }).catch(function(error){
                    console.log(error)
                })
                alert('Changes saved successfully')
                this.list_tyres()

                
            }


		},
        created(){
            this.list_tyres()
        }
	}

</script>