<template>

<section class="authentication-bg">
<div class="account-pages my-5">
<div class="container">
<div class="row justify-content-center">
<div class="col-xl-10">
<div class="card">
    <div class="card-body p-0">
        <div class="row">
            <div class="col-md-6 p-5">
                <div class="mx-auto mb-5">
                    <a href="index.html">
                        
                        <h3 class="d-inline align-middle ml-1">Precise Automotives</h3>
                    </a>
                </div>

                <h6 class="h5 mb-0 mt-4">Welcome back!</h6>
                <p class="text-muted mt-1 mb-4">Enter your email address and password to
                    access admin panel.</p>

                <form @submit.prevent="login()" class="authentication-form">
                    <div class="form-group">
                        <label class="form-control-label">Email Address</label>
                        <div class="input-group input-group-merge">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="icon-dual bi bi-envelope"></i>
                                </span>
                            </div>
                            <input type="email" class="form-control" v-model="email" placeholder="hello@gmail.com">
                        </div>
                    </div>

                    <div class="form-group mt-4">
                        <label class="form-control-label">Password</label>
                        
                        <div class="input-group input-group-merge">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="icon-dual bi bi-lock"></i>
                                </span>
                            </div>
                            <input type="password" class="form-control" v-model="password"
                                placeholder="Enter your password">
                        </div>
                    </div>

                    <div class="form-group mb-4">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input text-warning"
                                id="checkbox-signin" checked>
                            <label class="custom-control-label" for="checkbox-signin">Remember
                                me</label>
                        </div>
                    </div>

                    <div class="form-group mb-0 text-center">
                        <button class="btn btn-primary btn-block text-warning" type="submit"> Log In
                        </button>
                    </div>
                </form>
               
                
            </div>
            <div class="col-lg-6 d-none d-md-inline-block">
                <div class="auth-page-sidebar">
                    <div class="overlay"></div>
                    
                </div>
            </div>
        </div>
        
    </div> <!-- end card-body -->
</div>
<!-- end card -->



</div> <!-- end col -->
</div>
<!-- end row -->
</div>
<!-- end container -->
</div>
<!-- end page -->
</section>

</template>



<script>
	import axios from 'axios'
	export default {
		name : 'login',

		data(){
			return {
				email : '',
				password : ''
			}
		},

		methods : {
			async login(){
				if(!this.email){
					alert('Email cannot be blank')
					return
				}

				if(!this.password){
					alert('password cannot be blank')
					return
				}

				const res = await axios.post(this.$store.state.api_url+'api/login',{
					email : this.email,
					password : this.password
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				if(res=='success'){
					this.$router.push('/dashboard')
				}else{
					alert('Wrong email or password!')
				}
			}
		}
	}

</script>