<template>
	

            <!-- ========== Left Sidebar Start ========== -->
            <div class="left-side-menu">
                <div class="media user-profile mt-2 mb-2">
                    <img src="/assets/images/users/avatar-7.jpg" class="avatar-sm rounded-circle mr-2" alt="Shreyu" />
                    <img src="/assets/images/users/avatar-7.jpg" class="avatar-xs rounded-circle mr-2" alt="Shreyu" />

                    <div class="media-body">
                        <h6 class="pro-user-name mt-0 mb-0">John Doe</h6>
                        <span class="pro-user-desc">Administrator</span>
                    </div>
                   
                </div>
                <div class="sidebar-content">
                    <!--- Sidemenu -->
                    <div id="sidebar-menu" class="slimscroll-menu">
                        <ul class="metismenu" id="menu-bar">
                            <li class="menu-title">Navigation</li>

                            <li>
                                <router-link to="/dashboard">
                                    <i class="bi bi-house"></i>
                                    <span class="badge badge-success float-right">1</span>
                                    <span> Dashboard </span>
                                </router-link>
                            </li>
                            <li class="menu-title">Apps</li>
 

                             <li>
    	<div class="btn-group mt-2 mr-1 ml-4">
                    <button type="button" class="btn dropdown-toggle text-dark"
                        data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false"><i class="bi bi-battery"></i> Batteries <i class="icon"><span class="bi bi-chevron-down"></span></i></button>
                    <div class="dropdown-menu">
                        <router-link to="/new-battery" class="dropdown-item"> <i class="bi bi-plus"></i> Add new</router-link>
                        <router-link to="/batteries" class="dropdown-item"> <i class="bi bi-list"></i> List </router-link>
                        
                       
                    </div>
                </div>
                            </li>

                              <li>
    	<div class="btn-group mt-2 mr-1 ml-4">
                    <button type="button" class="btn dropdown-toggle text-dark"
                        data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false"><i class="bi bi-0-circle"></i> Tyres <i class="icon"><span class="bi bi-chevron-down"></span></i></button>
                    <div class="dropdown-menu">
                          <router-link to="/new-tyre" class="dropdown-item"> <i class="bi bi-plus"></i> Add new</router-link>
                        <router-link to="/tyres" class="dropdown-item"> <i class="bi bi-list"></i> List </router-link>
                    </div>
                </div>
                            </li>

                           

                            <li class="menu-title">Settings</li>

                            <li> <button class="btn text-dark ml-4" @click="logout()">
                                    <i class="bi bi-power"></i>
                                    <span> Log out </span>
                                </button> </li>
                           

                                                

                           
                        </ul>
                    </div>
                    <!-- End Sidebar -->

                    <div class="clearfix"></div>
                </div>
                <!-- Sidebar -left -->

            </div>
            <!-- Left Sidebar End -->
</template>


<script>
	export default{
		name : 'side-bar',

		methods : {
			logout(){
				this.$store.state.logged_in = false
				localStorage.setItem('logged_in',false)
				this.$router.push('/')
			}
		}
	}
</script>