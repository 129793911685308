<template>

<section>
     <!-- Begin page -->
        <div id="wrapper">
            <Header />
            <SideBar />


            <!-- ============================================================== -->
            <!-- Start Page Content here -->
            <!-- ============================================================== -->

            <div class="content-page">
                <div class="content">
                    
                    <!-- Start Content-->
                    <div class="container-fluid">
                        <div class="row page-title">
                            <div class="col-md-12">
                               
                                <h4 class="mb-1 mt-0">New tyre</h4>
                            </div>
                        </div>


                        <div class="card">
                            <div class="card-body">
                                <h6 class="mb-1 mt-0">This information will be used on the search page</h6>
                                <form @submit.prevent="new_tyre()" enctype="multipart/form-data">
                                    <div class="row">
                                        <div class="col-md-12 mt-3">
                                            <label>Photo</label>
                                            <input type="file" placeholder="Photo" class="form-control" @change="get_image">
                                        </div>
                                        <div class="col-md-6 mt-3">
                                            <input type="text" placeholder="Pattern" v-model="pattern" class="form-control">
                                        </div>

                                         <div class="col-md-6 mt-3">
                                            <input type="text" placeholder="Size(Part no.)" v-model="size" class="form-control">
                                        </div>

                                          <div class="col-md-6 mt-3">
                                            <input type="text" placeholder="Brand" v-model="brand" class="form-control">
                                        </div>

                                          <div class="col-md-6 mt-3">
                                            <input type="text" placeholder="Retail price" v-model="price" class="form-control">
                                        </div>

                                          <div class="col-md-6 mt-3">
                                            <button class="btn btn-primary text-warning" :disabled="disabled">{{text}}</button>
                                        </div>
                                        
                                    </div>
                                </form>
                            </div>
                        </div>



                    </div> <!-- container-fluid -->

                </div> <!-- content -->

                
                <Footer />
               

            </div>

            <!-- ============================================================== -->
            <!-- End Page content -->
            <!-- ============================================================== -->


        </div>
        <!-- END wrapper -->
</section>

</template>



<script>
	import axios from 'axios'
    import Header from './layouts/Header'
    import SideBar from './layouts/SideBar'
    import Footer from './layouts/Footer'

	export default {
		name : 'new-tyre',
        components : {Header,SideBar,Footer},

		data(){
			return {
			pattern : '',
            size : '',
            brand : '',
            price : '',
            image : '',
            text : 'Submit',
            disabled : false	
			}
		},

		methods : {
               get_image(e){
            this.image = e.target.files[0] 
        },
			async new_tyre(){
                if(!this.pattern){
                    alert('Pattern cannot be blank!')
                    return 
                }

                 if(!this.size){
                    alert('Size or part no. cannot be blank!')
                    return 
                }

                 if(!this.brand){
                    alert('Brand cannot be blank!')
                    return 
                }

                 if(!this.price){
                    alert('Price cannot be blank!')
                    return 
                }

                //upload a file
const config = {
       headers: { 'content-type': 'multipart/form-data' }
   }
   //upload a file

   this.text = 'Please wait...'
   this.disabled = true

                const res = await axios.post(this.$store.state.api_url+'api/new-tyre',{
                    pattern : this.pattern,
                    size : this.size,
                    brand : this.brand,
                    price : this.price,
                    image : this.image
                },config).then(function(response){
                    return response.data
                }).catch(function(error){
                    console.log(error)
                })
                alert('Tyre added to list')
                this.pattern = ''
                this.size = ''
                this.price = ''
                this.brand = ''
                this.image = ''
                this.text = 'Submit'
                this.disabled = false
            }
		}
	}

</script>