<template>
	<!-- Topbar Start -->
            <div class="navbar navbar-expand flex-column flex-md-row navbar-custom">
                <div class="container-fluid">
                    <!-- LOGO -->
                    <router-link to="/dashboard" class="navbar-brand mr-0 mr-md-2 logo">
                        <span class="logo-lg">
                           
                            <span class="d-inline h5 ml-1">Precise Automotives</span>
                        </span>
                        <span class="logo-sm">
                            <img src="/assets/images/logo.png" alt="" height="24">
                        </span>
                    </router-link>

                    <ul class="navbar-nav bd-navbar-nav flex-row list-unstyled menu-left mb-0">
                        <li class="">
                            <button class="button-menu-mobile open-left disable-btn">
                                <i class="bi bi-list menu-icon"></i>
                                <i class="bi bi-x-lg  close-icon"></i>
                            </button>
                        </li>
                    </ul>

                    <ul class="navbar-nav flex-row ml-auto d-flex list-unstyled topnav-menu float-right mb-0">
                   

                        <li class="dropdown notification-list" data-toggle="tooltip" data-placement="left" title="Settings">
                            <button class="nav-link right-bar-toggle btn" @click="logout()">
                                <i class="bi bi-power"></i> Log out
                            </button>
                        </li>

                        
                    </ul>
                </div>

            </div>
            <!-- end Topbar -->
</template>


<script>
	export default{
		name : 'header',

		methods : {
			logout(){
				this.$store.state.logged_in = false
				localStorage.setItem('logged_in',false)
				this.$router.push('/')
			}
		}
	}
</script>